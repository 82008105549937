import React from 'react'

const ChooseGame = ({userGame,setUserGame,isDashboard}) => {

    

    return (
        <>
        <select value={userGame} style={{fontSize:'2rem'}} 
        class={isDashboard?'dashBoardInput':'selectGender font26Mobile'} onInput={(e)=>setUserGame(e.target.value)}>
            <option selected disabled  value="no-game">choose game</option>
            <option value="no-game">no game</option>
            <option value="chess">chess</option>
            <option value="ticTak">ticTak</option>
            <option value="superTicTak">superTicTak</option>
        </select>
        </>
    );

}
export default ChooseGame
