 
 const countryListdublicates=[
"Côte d'Ivoire"
,
"Ghana"
,
"United States"
,
"Ethiopia"
,
"Australia"
,
"Yemen"
,
"Algeria"
,
"Kazakhstan"
,
"Jordan"
,
"Netherlands"
,
"Russia"
,
"United States"
,
"Andorra"
,
"Anguilla"
,
"Madagascar"
,
"Antigua & Barbuda"
,
"Samoa (western)"
,
"Kazakhstan"
,
"Kazakhstan"
,
"Brazil"
,
"Aruba"
,
"Turkmenistan"
,
"Eritrea"
,
"Russia"
,
"Paraguay"
,
"Greece"
,
"Canada"
,
"Kazakhstan"
,
"New Zealand"
,
"Portugal"
,
"Iraq"
,
"Brazil"
,
"Mexico"
,
"Bahrain"
,
"Azerbaijan"
,
"Mali"
,
"Thailand"
,
"Central African Rep."
,
"Gambia"
,
"Barbados"
,
"Russia"
,
"Lebanon"
,
"Brazil"
,
"Serbia"
,
"Belize"
,
"Germany"
,
"Bermuda"
,
"United States"
,
"Kyrgyzstan"
,
"Guinea-Bissau"
,
"Canada"
,
"Malawi"
,
"Brazil"
,
"Colombia"
,
"United States"
,
"Papua New Guinea"
,
"Slovakia"
,
"Congo (Rep.)"
,
"Australia"
,
"Australia"
,
"Brunei"
,
"Belgium"
,
"Romania"
,
"Hungary"
,
"Argentina"
,
"Burundi"
,
"Germany"
,
"Egypt"
,
"Canada"
,
"Brazil"
,
"Spain"
,
"Mexico"
,
"Cape Verde"
,
"Venezuela"
,
"Morocco"
,
"Antarctica"
,
"Argentina"
,
"French Guiana"
,
"Cayman Islands"
,
"United States"
,
"Spain"
,
"British Indian Ocean Territory"
,
"New Zealand"
,
"United States"
,
"Mexico"
,
"Moldova"
,
"Russia"
,
"Mongolia"
,
"Christmas Island"
,
"Micronesia"
,
"Cocos (Keeling) Islands"
,
"Sri Lanka"
,
"Comoros"
,
"Guinea"
,
"Denmark"
,
"Argentina"
,
"Costa Rica"
,
"Canada"
,
"Brazil"
,
"Curaçao"
,
"Senegal"
,
"Syria"
,
"Greenland"
,
"Tanzania"
,
"Australia"
,
"Antarctica"
,
"Canada"
,
"Canada"
,
"United States"
,
"United States"
,
"Bangladesh"
,
"East Timor"
,
"Djibouti"
,
"Dominica"
,
"Cameroon"
,
"United Arab Emirates"
,
"Ireland"
,
"Antarctica"
,
"Tajikistan"
,
"Chile"
,
"Canada"
,
"Vanuatu"
,
"Brazil"
,
"Western Sahara"
,
"El Salvador"
,
"Australia"
,
"Tokelau"
,
"Cyprus"
,
"Faroe Islands"
,
"Fiji"
,
"Canada"
,
"Brazil"
,
"Sierra Leone"
,
"Tuvalu"
,
"Botswana"
,
"Ecuador"
,
"French Polynesia"
,
"Palestine"
,
"Gibraltar"
,
"Canada"
,
"Canada"
,
"Turks & Caicos Is"
,
"Grenada"
,
"Solomon Islands"
,
"Guadeloupe"
,
"Guam"
,
"Guatemala"
,
"Ecuador"
,
"Guernsey"
,
"Guyana"
,
"Canada"
,
"Zimbabwe"
,
"Cuba"
,
"Palestine"
,
"Finland"
,
"Mexico"
,
"Vietnam"
,
"Australia"
,
"Hong Kong"
,
"United States"
,
"Mongolia"
,
"United States"
,
"Canada"
,
"Canada"
,
"Russia"
,
"Isle of Man"
,
"Turkey"
,
"Indonesia"
,
"Jamaica"
,
"Indonesia"
,
"Jersey"
,
"South Africa"
,
"South Sudan"
,
"Argentina"
,
"United States"
,
"Afghanistan"
,
"Russia"
,
"Russia"
,
"Uganda"
,
"Kiribati"
,
"Pakistan"
,
"Nepal"
,
"French Southern & Antarctic Lands"
,
"Russia"
,
"Sudan"
,
"Rwanda"
,
"Congo (Dem. Rep.)"
,
"Kiribati"
,
"Russia"
,
"United States"
,
"India"
,
"Micronesia"
,
"Caribbean NL"
,
"Russia"
,
"Malaysia"
,
"Malaysia"
,
"Kuwait"
,
"Marshall Islands"
,
"Ukraine"
,
"Bolivia"
,
"Argentina"
,
"Nigeria"
,
"Gabon"
,
"Peru"
,
"Australia"
,
"Portugal"
,
"Slovenia"
,
"Togo"
,
"Britain (UK)"
,
"Svalbard & Jan Mayen"
,
"Australia"
,
"United States"
,
"United States"
,
"St Maarten (Dutch)"
,
"Angola"
,
"Congo (Dem. Rep.)"
,
"Zambia"
,
"Luxembourg"
,
"Macau"
,
"Brazil"
,
"Australia"
,
"Portugal"
,
"Spain"
,
"Russia"
,
"Seychelles"
,
"Marshall Islands"
,
"Indonesia"
,
"Equatorial Guinea"
,
"Maldives"
,
"Malta"
,
"Nicaragua"
,
"Brazil"
,
"Philippines"
,
"Mozambique"
,
"United States"
,
"Åland Islands"
,
"St Martin (French)"
,
"French Polynesia"
,
"Martinique"
,
"Lesotho"
,
"Mexico"
,
"Mauritius"
,
"Antarctica"
,
"Mayotte"
,
"Mexico"
,
"Eswatini (Swaziland)"
,
"Antarctica"
,
"Australia"
,
"Argentina"
,
"United States"
,
"Mexico"
,
"United States"
,
"Mexico"
,
"US minor outlying islands"
,
"Belarus"
,
"St Pierre & Miquelon"
,
"Somalia"
,
"Monaco"
,
"Canada"
,
"Liberia"
,
"Mexico"
,
"Uruguay"
,
"United States"
,
"Montserrat"
,
"Russia"
,
"Oman"
,
"Kenya"
,
"Bahamas"
,
"Nauru"
,
"Chad"
,
"United States"
,
"United States"
,
"Niger"
,
"Cyprus"
,
"Canada"
,
"Niue"
,
"United States"
,
"Norfolk Island"
,
"Brazil"
,
"Mauritania"
,
"New Caledonia"
,
"Russia"
,
"Russia"
,
"Greenland"
,
"Mexico"
,
"Russia"
,
"Kazakhstan"
,
"Norway"
,
"Burkina Faso"
,
"Samoa (American)"
,
"Palau"
,
"Antarctica"
,
"Panama"
,
"Canada"
,
"Suriname"
,
"France"
,
"Australia"
,
"United States"
,
"Cambodia"
,
"United States"
,
"Pitcairn"
,
"Montenegro"
,
"Micronesia"
,
"Indonesia"
,
"Haiti"
,
"Papua New Guinea"
,
"Trinidad & Tobago"
,
"Benin"
,
"Brazil"
,
"Czech Republic"
,
"Puerto Rico"
,
"Chile"
,
"Korea (North)"
,
"Qatar"
,
"Kazakhstan"
,
"Kazakhstan"
,
"Canada"
,
"Canada"
,
"Cook Islands"
,
"Brazil"
,
"Canada"
,
"Canada"
,
"Réunion"
,
"Iceland"
,
"Latvia"
,
"Brazil"
,
"Argentina"
,
"Saudi Arabia"
,
"Italy"
,
"Antarctica"
,
"Northern Mariana Islands"
,
"Russia"
,
"Argentina"
,
"Russia"
,
"Uzbekistan"
,
"Argentina"
,
"Argentina"
,
"San Marino"
,
"Brazil"
,
"Chile"
,
"Dominican Republic"
,
"Brazil"
,
"Sao Tome & Principe"
,
"Bosnia & Herzegovina"
,
"Russia"
,
"Greenland"
,
"Korea (South)"
,
"China"
,
"Russia"
,
"Singapore"
,
"United States"
,
"North Macedonia"
,
"Bulgaria"
,
"South Georgia & the South Sandwich Islands"
,
"Russia"
,
"St Barthelemy"
,
"St Helena"
,
"Canada"
,
"St Kitts & Nevis"
,
"St Lucia"
,
"Virgin Islands (US)"
,
"St Vincent"
,
"Falkland Islands"
,
"Sweden"
,
"Canada"
,
"Australia"
,
"Antarctica"
,
"French Polynesia"
,
"Taiwan"
,
"Estonia"
,
"Kiribati"
,
"Uzbekistan"
,
"Georgia"
,
"Honduras"
,
"Iran"
,
"United States"
,
"Bhutan"
,
"Greenland"
,
"Canada"
,
"Mexico"
,
"Albania"
,
"Japan"
,
"Russia"
,
"Tonga"
,
"Canada"
,
"Virgin Islands (UK)"
,
"Libya"
,
"Antarctica"
,
"Argentina"
,
"Tunisia"
,
"Mongolia"
,
"Russia"
,
"China"
,
"Argentina"
,
"Russia"
,
"Ukraine"
,
"Liechtenstein"
,
"Canada"
,
"Vatican City"
,
"United States"
,
"Austria"
,
"Laos"
,
"Lithuania"
,
"United States"
,
"Russia"
,
"Russia"
,
"Antarctica"
,
"US minor outlying islands"
,
"Wallis & Futuna"
,
"Poland"
,
"Canada"
,
"United States"
,
"Namibia"
,
"Canada"
,
"United States"
,
"Russia"
,
"Myanmar (Burma)"
,
"Russia"
,
"Canada"
,
"Armenia"
,
"Croatia"
,
"Ukraine"
,
"Switzerland"
]
export const countryList = [...new Set(countryListdublicates)];