import React from "react";
import { Link } from "react-router-dom";
import "./Policies.scss";
 
function Terms() {
  return (
    <>
 
      <style jsx>{`
                .goog-te-combo{ 
                    font-size:2rem;
                }
                #google_translate_element{ 
                    position:absolute;
                    right:0
                }
                `}</style>
      <div className="Pcontent">
        <h1>Frndsmeet Terms of Service Agreement</h1>
        <p>
          <strong>Last Updated: 16-12-2021</strong>
        </p>
        <p>
          IMPORTANT: PLEASE REVIEW THE ARBITRATION AGREEMENT AND CLASS ACTION
          WAIVER SET FORTH IN SECTION 9 BELOW CAREFULLY, AS IT WILL REQUIRE YOU
          TO RESOLVE DISPUTES WITH Frndsmeet ON AN INDIVIDUAL BASIS THROUGH
          FINAL AND BINDING ARBITRATION. BY ENTERING INTO THIS AGREEMENT, YOU
          EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE
          TERMS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE
          CONSEQUENCES OF THIS IMPORTANT DECISION.
        </p>
        <h2>1. Applicability and Acceptance of These Terms</h2>
        <p>
          This Terms of Service Agreement (“<strong>Agreement</strong>” or “
          <strong>Terms</strong>”) is a legal agreement between you and
          Frndsmeet.com . (“<strong>Frndsmeet</strong>”, “<strong>we</strong>”,
          or “<strong>us</strong>”). By accessing or using the Frndsmeet
          website, currently located at Frndsmeet.com (the “
          <strong>Site</strong>”), or any apps or other services offered or
          operated by Frndsmeet (collectively, the “<strong>Services</strong>”),
          or by checking a box or clicking a button signifying your acceptance
          of these Terms, you acknowledge that you have read, understood and
          agree to be bound by these Terms. If you do not agree to these Terms,
          do not access or use any of the Services.
        </p>
        <p>
          When using the Services, you will be subject to Frndsmeet’s Community
          Guidelines (“<strong>Community Guidelines</strong>”) found{" "}
          <Link to="/Guidelines">here</Link>, and any additional guidelines,
          policies or rules posted on the Services or otherwise made available
          or disclosed to you (collectively, the “<strong>Rules</strong>
          ”). All such guidelines, policies and rules are incorporated into
          these Terms by this reference.
        </p>
        <h2>2. Use of the Services by Minors and Banned Persons</h2>
        <p>
          The Services are not available to, and shall not be accessed or used
          by, persons under the age of 13. If you are between the ages of 13 and
          the age of legal majority in your jurisdiction of residence, you may
          only use the Services with the permission and under the supervision of
          a parent or legal guardian who agrees to be bound by these Terms. BY
          ACCESSING OR USING THE SERVICES, YOU REPRESENT AND WARRANT THAT YOU
          ARE AT LEAST 13 YEARS OF AGE AND THAT YOUR PARENT OR LEGAL GUARDIAN
          AGREES TO BE BOUND BY THESE TERMS IF YOU ARE BETWEEN 13 AND THE AGE OF
          LEGAL MAJORITY IN YOUR JURISDICTION OF RESIDENCE.
        </p>
        <p>
          The Services are also not available to, and shall not be accessed or
          used by, any users previously blocked or otherwise banned from
          accessing or using the Services.
        </p>
        <h2>3. Limited License to Use the Services</h2>
        <p>
          Subject to your compliance with these Terms and all other applicable
          Rules including but not limited to the Community Guidelines, you are
          granted a limited, non-exclusive, non-sublicensable, revocable,
          non-transferable license to access and use the Services solely for
          your personal and non-commercial use. No licenses or rights are
          granted to you by implication or otherwise under any intellectual
          property rights owned or controlled by Frndsmeet or its licensors,
          except for licenses and rights expressly granted in these Terms.
          Frndsmeet can terminate this license as provided in Section 10 below.
        </p>
        <p>
          You are solely responsible for compliance with any and all laws,
          rules, and regulations that may apply to your use of the Services. You
          agree that you will comply with these Terms and the Community
          Guidelines and will not, and will not assist or enable others to:
        </p>
        <ul>
          <li>
            breach or circumvent any applicable laws or regulations, agreements
            with third parties, third-party rights, or our Terms or Rules;
          </li>
          <li>
            use the Services for any commercial or other purposes that are not
            expressly permitted by these Terms or in a manner that falsely
            implies Frndsmeet’s endorsement, partnership or otherwise misleads
            others as to your affiliation with Frndsmeet;
          </li>
          <li>
            license, sell, transfer, assign, distribute, host, or otherwise
            commercially exploit the Services;
          </li>
          <li>
            except as explicitly stated herein, copy, reproduce, distribute,
            republish, download, display, post or transmit the Services, in
            whole or in part, in any form or by any means;
          </li>
          <li>
            use, display, mirror or frame the Services or any individual element
            within the Services, the Frndsmeet name, any Frndsmeet trademark,
            logo or other proprietary information, or the layout and design of
            any page or form contained on a page in the Services, without
            Frndsmeet’ express written consent;
          </li>
          <li>
            use any robots, spider, crawler, scraper or other automated means or
            processes to access, collect data or other content from or otherwise
            interact with the Services for any purpose;
          </li>
          <li>
            avoid, bypass, remove, deactivate, impair, descramble, or otherwise
            attempt to circumvent any technological measure implemented by
            Frndsmeet or any of Frndsmeet’s providers to protect the Services;
          </li>
          <li>
            modify, make derivative works of, attempt to decipher, decompile,
            disassemble or reverse engineer any of the software used to provide
            the Services;
          </li>
          <li>
            take any action that damages or adversely affects, or could damage
            or adversely affect the performance or proper functioning of the
            Services; or
          </li>
          <li>
            violate or infringe anyone else’s rights or otherwise cause or
            threaten harm to anyone.
          </li>
        </ul>
        <p>
          Neither the above restrictions, nor the Community Guidelines, the
          Rules, or anything else in the Terms, shall be construed to create any
          rights enforceable by users, whether as third-party beneficiaries or
          otherwise. Frndsmeet has the right, but not the obligation, to enforce
          any of the foregoing.
        </p>
        <h2>4. User Content and Conduct; User Disputes</h2>
        <p>
          The Services provide communication channels designed to enable users
          to communicate with other users. Frndsmeet does not exert any control
          over the individuals you interact with, Frndsmeet has no obligation to
          monitor these communication channels but may, in its discretion, do so
          in connection with providing the Services. Frndsmeet may also
          terminate, suspend or ban your access to and use of the Services at
          any time, without notice, for any reason in its sole discretion. You
          acknowledge and agree that any user content, including without
          limitation text chats and video chats, is not created, endorsed or
          controlled by Frndsmeet. Frndsmeet will not under any circumstances be
          liable for any user content or activity within the Services. Frndsmeet
          is not responsible for information or content that you choose to share
          within or through the Services nor is Frndsmeet responsible for the
          content or actions of other users of the Services. Frndsmeet is not
          responsible for maintaining copies of any information or
          communications you choose to submit to or through the Services.
        </p>
        <p>
          You are solely responsible for your interaction with other users of
          the Services and other parties that you come in contact with through
          the Services. To the fullest extent permitted by applicable law,
          Frndsmeet hereby disclaims any and all liability to you or any third
          party relating to your use of the Services. You acknowledge and agree
          that Frndsmeet does not have any special relationship with you as an
          end user, and as such, Frndsmeet does not owe you any duty to protect
          you from the acts of other users or other third parties.
        </p>
        <p>
          Parental control protections (such as computer hardware, software, or
          filtering services) are commercially available and may assist you in
          limiting minors’ access to materials that may be harmful to or
          inappropriate for minors. There are a number of websites that provide
          information about such parental control protections, including but not
          limited to{" "}
          <a href="https://www.connectsafely.org/controls/">
            https://www.connectsafely.org/controls/
          </a>
          .
        </p>
        <h2>5. Intellectual Property Rights</h2>
        <p>
          The Services may, in their entirety or in part, be protected by
          copyright, trademark and/or other laws of the United States and other
          countries. You acknowledge and agree that the Services, including all
          associated intellectual property rights, are the exclusive property of
          Frndsmeet and/or its licensors or authorizing third parties. You will
          not remove, alter or obscure any copyright, trademark, service mark or
          other proprietary rights notices incorporated in or accompanying the
          Services. All trademarks, service marks, logos, trade names, trade
          dress and any other source identifiers of Frndsmeet used on or in
          connection with the Services (collectively, the “
          <strong>Marks</strong>”) are trademarks or registered trademarks of
          Frndsmeet in the India and abroad. Trademarks, service marks, logos,
          trade names and any other proprietary designations of third parties
          used on or in connection with the Services are used for identification
          purposes only and may be the property of their respective owners. Use
          of any third-party trademark is intended only to identify the
          trademark owner and its goods and services, and is not intended to
          imply any association between the trademark owner and Frndsmeet.
        </p>
        <h2>6. Assumption of Risk and Disclaimer of Warranties</h2>
        <p>
          <strong>
            <u>Assumption of Risk</u>.
          </strong>{" "}
          You acknowledge and agree that use of the Services, including your
          interactions with other users, may carry inherent risk and by
          accessing and using the Services, you choose to assume those risks
          voluntarily. To the fullest extent permitted by applicable law, you
          assume full responsibility for your use of the Services, including
          your interactions with other users.
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, YOU KNOWINGLY,
          VOLUNTARILY AND FREELY ASSUME ALL RISKS, BOTH KNOWN AND UNKNOWN, OF
          ACCESSING OR USING THE SERVICES, EVEN IF THOSE RISKS ARISE FROM THE
          NEGLIGENCE OR CARELESSNESS OF Frndsmeet, THIRD-PARTIES INCLUDING OTHER
          USERS OF THE SERVICES, OR DEFECTS IN THE SERVICES.
        </p>
        <p>
          <strong>
            <u>No Warranties</u>.
          </strong>{" "}
          TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, Frndsmeet
          PROVIDES THE SERVICES ON AN “AS IS” AND “AS AVAILABLE” AND “WITH ALL
          FAULTS” BASIS, WITHOUT WARRANTY OF ANY KIND. TO THE FULLEST EXTENT
          PERMISSIBLE UNDER APPLICABLE LAW, Frndsmeet AND ITS AFFILIATES AND
          LICENSORS DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND, EXPRESS
          OR IMPLIED, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF TITLE, IMPLIED
          WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE (EVEN IF Frndsmeet IS ADVISED OF SUCH PURPOSE), AND
          IMPLIED WARRANTIES ARISING FROM A PARTICULAR COURSE OF DEALING OR
          USAGE OF TRADE. WITHOUT LIMITING THE FOREGOING, NEITHER Frndsmeet NOR
          ANY OF ITS AFFILIATES OR LICENSORS, NOR ANY OF ITS OR THEIR OFFICERS,
          DIRECTORS, LICENSORS, EMPLOYEES OR REPRESENTATIVES REPRESENT OR
          WARRANT (I) THAT THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE
          ACCURATE, TRUTHFUL, COMPLETE, RELIABLE, OR ERROR FREE, (II) THAT THE
          SERVICES WILL ALWAYS BE AVAILABLE OR WILL BE UNINTERRUPTED,
          ACCESSIBLE, TIMELY, RESPONSIVE, OR SECURE, (III) THAT ANY ERRORS OR
          DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES WILL BE FREE FROM
          VIRUSES, WORMS, TROJAN HORSES OR OTHER HARMFUL PROPERTIES, (IV) THE
          ACCURACY, RELIABILITY, TIMELINESS OR COMPLETENESS OF ANY CONTENT
          AVAILABLE ON OR THROUGH THE SERVICES, (V) ANY IMPLIED WARRANTY ARISING
          FROM COURSE OF DEALING OR USAGE OF TRADE, OR (VI) THAT ANY CONTENT
          PROVIDED VIA THE SERVICES IS NON-INFRINGING. NO INFORMATION OR ADVICE
          PROVIDED THROUGH THE SERVICES BY Frndsmeet OR BY Frndsmeet’S EMPLOYEES
          OR AGENTS SHALL CREATE ANY WARRANTY.{" "}
          <strong>
            Some jurisdictions do not allow the exclusion of certain warranties,
            so some of the above limitations and exclusions may not apply to
            you.
          </strong>
        </p>
        <p>
          <strong>
            <u>Other Users of the Services</u>.
          </strong>{" "}
          Frndsmeet HAS NO CONTROL OVER AND DOES NOT MAKE, AND HEREBY EXPRESSLY
          DISCLAIMS, ANY REPRESENTATIONS, WARRANTIES OR GUARANTEES AS TO THE
          CONDUCT, ACTS OR OMISSIONS OF OTHER USERS OF THE SERVICES. YOU
          ACKNOWLEDGE AND AGREE THAT YOU SHALL LOOK SOLELY TO THE OTHER USERS,
          AND NOT Frndsmeet, WITH RESPECT TO ANY CLAIMS OR CAUSES OF ACTION
          ARISING FROM OR RELATING TO THE ACTIONS OR CONDUCT OF OTHER USERS OF
          THE SERVICES. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW,
          UNDER NO CIRCUMSTANCES SHALL Frndsmeet BE RESPONSIBLE FOR ANY LOSS,
          DAMAGE OR INJURY RESULTING FROM ANY ACTION, CONDUCT OR OMISSION OF ANY
          OTHER USER OF THE SERVICES.
        </p>
        <h2>7. Limitation of Liability</h2>
        <p>
          <strong>
            <u>Limitations on Frndsmeet’s Liability</u>.
          </strong>{" "}
          YOU ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY
          LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE
          SERVICES REMAINS WITH YOU. NEITHER Frndsmeet NOR ANY OTHER PARTY
          INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
          PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION,
          COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS
          OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR
          EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH (I) THESE
          TERMS, (II) THE USE OF THE SERVICES, INCLUDING BUT NOT LIMITED TO ANY
          DAMAGE CAUSED BY ANY RELIANCE ON, OR ANY DELAYS, INACCURACIES, ERRORS
          OR OMISSIONS IN, THE SERVICES, WHETHER PROVIDED BY Frndsmeet OR BY
          THIRD PARTIES, (III) THE USE OF OR INABILITY TO USE THE SERVICES FOR
          ANY REASON, OR (IV) YOUR COMMUNICATIONS, INTERACTIONS OR DEALINGS
          WITH, OR THE CONDUCT OF, OTHER USERS OF THE SERVICES, WHETHER BASED ON
          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR
          ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Frndsmeet HAS BEEN INFORMED
          OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH
          HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
        </p>
        <p>
          IN NO EVENT WILL Frndsmeet’S AGGREGATE LIABILITY ARISING OUT OF OR IN
          CONNECTION WITH THESE TERMS OR YOUR USE OF OR INABILITY TO USE THE
          SERVICES (INCLUDING BUT NOT LIMITED TO YOUR INTERACTIONS WITH OTHER
          USERS OF THE SERVICES) EXCEED ONE HUNDRED U.S. DOLLARS (U.S. $100.00).
        </p>
        <p>
          The limitations of damages set forth above are fundamental elements of
          the basis of the bargain between Frndsmeet and you. Some jurisdictions
          do not allow the exclusion or limitation of liability for
          consequential or incidental damages, so some of the above limitations
          and exclusions may not apply to you.
        </p>
        <p>
          <strong>
            <u>No Liability for Non-Frndsmeet Actions</u>.
          </strong>{" "}
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          Frndsmeet BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT,
          INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR
          INCIDENTAL, ARISING OUT OF OR RELATING TO THE CONDUCT, ACTS OR
          OMISSIONS OF YOU OR ANY OTHER THIRD PARTY, INCLUDING OTHER USERS OF
          THE SERVICES, IN CONNECTION WITH THE USE OF THE SERVICES, INCLUDING
          WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY
          OTHER DAMAGES. Some jurisdictions do not allow the exclusion or
          limitation of liability for consequential or incidental damages, so
          some of the above limitations and exclusions may not apply to you.
        </p>
        <h2>8. Indemnification</h2>
        <p>
          To the maximum extent permitted by applicable law, you agree to
          release, defend (at Frndsmeet’s option), indemnify, and hold Frndsmeet
          and its affiliates and subsidiaries, and their officers, directors,
          employees and agents, harmless from and against any claims,
          liabilities, damages, losses, and expenses, including without
          limitation, reasonable attorney and accounting fees, arising out of or
          in any way connected with (i) your breach or alleged breach of these
          Terms or any other applicable policies of Frndsmeet (including but not
          limited to the Guidelines or Rules), (ii) your use of the Services
          other than as authorized by these Terms, the Guidelines or Rules,
          (iii) your interactions with other users of the Services, including
          without limitation any injuries, losses or damages (whether
          compensatory, direct, incidental, consequential or otherwise) of any
          kind arising in connection with or as a result of your interactions,
          (iv) any information or materials you submit through the Services, or
          (v) your violation, or alleged violation, of any laws, regulations or
          third-party rights (all of the foregoing, “<strong>Claims</strong>”).
          Frndsmeet may assume exclusive control of any defense of any Claims
          (which shall not excuse your obligation to indemnify Frndsmeet), and
          you agree to fully cooperate with Frndsmeet in such event. You shall
          not settle any Claims without prior written consent from Frndsmeet.
        </p>
        <h2>9. Dispute Resolution: Agreement to Arbitrary</h2>
        <p>
          <strong>
            Please read the following Section 9 carefully, as they affect your
            rights.
          </strong>
        </p>
        <h3>9.1 Agreement to Arbitrate and Timing of Claims</h3>
        <p>
          YOU AND Frndsmeet MUTUALLY AGREE THAT ANY DISPUTE, CLAIM OR
          CONTROVERSY ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OR
          THE APPLICABILITY, BREACH, TERMINATION, VALIDITY, ENFORCEMENT OR
          INTERPRETATION THEREOF OR TO THE ACCESS TO AND USE OF THE SERVICES,
          WHETHER BASED IN CONTRACT, STATUTE, REGULATION, ORDINANCE, TORT
          (INCLUDING WITHOUT LIMITATION, FRAUD, MISREPRESENTATION, FRAUDULENT
          INDUCEMENT, OR NEGLIGENCE), OR ANY OTHER LEGAL OR EQUITABLE THEORY
          (COLLECTIVELY, “<strong>DISPUTE</strong>”) WILL BE SETTLED BY BINDING
          INDIVIDUAL ARBITRATION (THE “<strong>ARBITRATION AGREEMENT</strong>”).
          ARBITRATION MEANS THAT THE DISPUTE WILL BE RESOLVED BY A NEUTRAL
          ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY. THE ARBITRATOR
          WILL DECIDE ALL THRESHOLD QUESTIONS, INCLUDING BUT NOT LIMITED TO
          ISSUES RELATING TO THE ENFORCEABILITY, REVOCABILITY, OR VALIDITY OF
          THIS ARBITRATION AGREEMENT AND WHETHER EITHER PARTY LACKS STANDING TO
          ASSERT HIS/HER/ITS CLAIM(S).
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT, REGARDLESS OF ANY STATUTE OR LAW TO
          THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED
          TO THESE TERMS OR YOUR USE OF THE SERVICES MUST BE FILED WITHIN ONE
          (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER
          BARRED.
        </p>
        <h3>9.2 Exceptions to the Arbitration Agreement</h3>
        <p>
          Notwithstanding the Arbitration Agreement, you and Frndsmeet each
          agree that (i) any dispute that may be brought in small claims court
          may be instituted in a small claims court of competent jurisdiction,
          (ii) either you or Frndsmeet may seek injunctive relief in any court
          of competent jurisdiction to enjoin infringement or other misuse of
          either party’s intellectual property rights (including without
          limitation, violation of any data use restrictions contained in these
          Terms or other misuse of the Services) or based on other exigent
          circumstances (e.g., imminent danger or commission of a crime,
          hacking, cyber-attack).
        </p>
        <h3>9.3 Pre-Arbitration Notification and Good Faith Negotiation</h3>
        <p>
          Prior to initiating an arbitration, you agree to provide Frndsmeet
          with notice of the dispute, which notice shall include a brief,
          written description of the dispute, the relief requested and your
          contact information. You must send any such notice to Frndsmeet by
          email at [support@frndsmeet.com] , with “Frndsmeet-Disputes” in the
          subject line, and by U.S. mail to Frndsmeet.com, LLC, c/o Northwest
          Registered Agent LLC, 7901 4th St. N., Suite 300, St. Petersburg, FL
          33702. The parties agree to use their best efforts to resolve any
          Dispute that is subject to the notification required under this
          section through informal negotiation, and good faith negotiations
          shall be a condition to either party initiating a lawsuit or
          arbitration in accordance with these Terms. If, after a good faith
          effort to negotiate, one of us feels the Dispute has not and cannot be
          resolved informally, the party intending to pursue arbitration agrees
          to notify the other party via email prior to initiating the
          arbitration.
        </p>
        <h3>9.4 The Arbitration</h3>
        <p>
          Except as provided herein, if we cannot resolve a Dispute by informal
          negotiation, any Dispute will be resolved only by binding arbitration
          to be conducted by JAMS under its then current and applicable rules
          and procedures (“<strong>JAMS Rules</strong>”), which are located at{" "}
          <a href="https://www.jamsadr.com">www.jamsadr.com</a>, and the rules
          set forth in these Terms. If there is a conflict between the JAMS
          Rules and the rules set forth in these Terms, the rules set forth in
          these Terms will govern.
        </p>
        <p>
          The arbitration will be conducted in English by a single arbitrator
          selected in accordance with JAMS Rules and those rules will govern the
          payment of all filing, administration, and arbitrator fees unless this
          Arbitration Agreement expressly provides otherwise. For U.S.
          residents, the arbitration shall be conducted in the U.S. state in
          which you reside (subject to the ability of either party to appear at
          any in-person hearing by telephone or other remote means, as provided
          below). For residents outside the United States, the arbitration shall
          be conducted in Portland, Oregon. If the value of the relief sought is
          U.S. $25,000 or less, the arbitration will be conducted based solely
          on written submissions; provided, however, that either party may
          request to have the arbitration conducted by telephone or other remote
          means or in-person hearing, which request shall be subject to the
          arbitrator’s discretion. Attendance at any in-person hearing may be
          made by telephone or other remote means by you and/or us, unless the
          arbitrator requires otherwise after hearing from the parties on the
          issue. Keeping in mind that arbitration is intended to be a fast and
          economical process, either party may file a dispositive motion to
          narrow the issues or claims. Subject to the exclusions and waivers in
          these Terms, the arbitrator may award any individual relief or
          individual remedies that are permitted by applicable law. The
          arbitrator’s award shall be made in writing but need not provide a
          statement of reasons unless requested by a party or required under
          applicable JAMS Rules. The arbitrator’s award shall be final and may
          be enforced in any court of competent jurisdiction. Each party shall
          pay its own attorneys’ fees and costs unless there is an applicable
          statutory provision requiring the prevailing party to be paid its
          attorneys’ fees and costs, in which case, a prevailing party
          attorneys’ fees award shall be determined by applicable law.
        </p>
        <p>
          The Federal Arbitration Act, applicable federal law, and the laws of
          the State of Oregon, without regard to principles of conflict of laws,
          will govern any Dispute.
        </p>
        <h3>9.5 No Class Actions or Representative Proceedings</h3>
        <p>
          YOU AND Frndsmeet ACKNOWLEDGE AND AGREE THAT TO THE FULLEST EXTENT
          PERMITTED BY LAW, WE ARE EACH WAIVING THE RIGHT TO PARTICIPATE AS A
          PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION LAWSUIT,
          CLASS-WIDE ARBITRATION, PRIVATE ATTORNEY GENERAL ACTION, OR ANY OTHER
          REPRESENTATIVE PROCEEDING AS TO ALL DISPUTES. YOU AND Frndsmeet AGREE
          THAT THERE WILL BE NO CLASS ARBITRATION OR ARBITRATION IN WHICH AN
          INDIVIDUAL ATTEMPTS TO RESOLVE A DISPUTE AS A REPRESENTATIVE OF
          ANOTHER INDIVIDUAL OR GROUP OF INDIVIDUALS. FURTHER, YOU AND Frndsmeet
          AGREE THAT A DISPUTE CANNOT BE BROUGHT AS A CLASS OR OTHER TYPE OF
          REPRESENTATIVE ACTION, WHETHER WITHIN OR OUTSIDE OF ARBITRATION, OR ON
          BEHALF OF ANY OTHER INDIVIDUAL OR GROUP OF INDIVIDUALS.
        </p>
        <p>
          If the class action waiver contained in this Section 9.5 is determined
          to be illegal or unenforceable, this entire Arbitration Agreement will
          be unenforceable, and the Dispute will be decided by the courts in the
          state of Oregon, Multnomah County, or the United States District Court
          for the Oregon, and the parties irrevocably submit to the exclusive
          jurisdiction of such courts.
        </p>
        <h3>9.6 Jury Trial Waiver</h3>
        <p>
          YOU AND Frndsmeet ACKNOWLEDGE AND AGREE THAT WE ARE EACH WAIVING THE
          RIGHT TO A TRIAL BY JURY AS TO ALL ARBITRABLE DISPUTES AND AS TO ANY
          DISPUTE THAT PROCEEDS IN COURT RATHER THAN ARBITRATION AS PROVIDED
          HEREIN.
        </p>
        <h3>9.7 Severability</h3>
        <p>
          Except as provided in Section 9.5, in the event that any portion of
          this Arbitration Agreement is deemed illegal or unenforceable, such
          provision shall be severed and the remainder of the Arbitration
          Agreement shall be given full force and effect. If the arbitrator
          determines this Section 9 is unenforceable, invalid or has been
          revoked as to any claim(s), then the Dispute as to such claim(s) will
          be decided by the courts in the state of Oregon, Multnomah County, or
          the United States District Court for the Oregon, and the parties
          irrevocably submit to the exclusive jurisdiction of such courts.
        </p>
        <h2>10. Term, Termination, and Survival</h2>
        <p>
          This Agreement will remain in full force and effect while you use the
          Services in accordance with these Terms and any additional applicable
          Rules. Frndsmeet may terminate this Agreement at any time without
          notice if we believe that you have breached this Agreement or the
          Community Guidelines, including but not limited to, by using the
          Services for non-personal use, engaging in prohibited activities, and
          any breach of your representations and warranties. All provisions of
          this Agreement which by their nature should survive termination shall
          survive termination, including without limitation, ownership
          provisions, warranty disclaimers, assumption of risk agreement,
          release of claims, indemnity, limitations of liability, and dispute
          resolution.
        </p>
        <h2>11. General</h2>
        <p>
          <p>
            Frndsmeet maintains a Privacy Policy describing the collection,
            retention, and use of information related to your use of the
            Services. You can find the Privacy Policy, which is incorporated by
            reference into this Agreement,<Link to="/Guidelines">here</Link>.
          </p>
          <p>
            Frndsmeet’s obligations are subject to existing laws and legal
            process. Therefore, Frndsmeet complies with valid legal process
            (e.g., court order, search warrant, subpoena or similar legal
            process) issued in compliance with applicable law from law
            enforcement agencies. Law enforcement may submit requests for
            information and legal process to Frndsmeet’s registered agent at the
            following address:
          </p>
        </p>
        <p>
          Law enforcement may also submit requests for information and legal
          process from an official government-issued email address (e.g.,law@frndsmeet.com)
          to Frndsmeet at law@frndsmeet.com with “Frndsmeet-LEO” in the subject line.{" "}
          <strong>
            Non-law enforcement requests should not be submitted to this email
            address. Frndsmeet will not respond to correspondence sent by
            non-law enforcement officials to this email address.
          </strong>{" "}
          Please note that the email address for law enforcement requests is
          provided for convenience only and does not waive any objections
          Frndsmeet may have, including the lack of jurisdiction or proper
          service.
        </p>
        <h3>11.2 Feedback</h3>
        <p>
          We welcome and encourage you to provide feedback, comments and
          suggestions for improvements to the Services (collectively, “
          <strong>Feedback</strong>”). You may submit Feedback by emailing us at support@frndsmeet.com
          with “Frndsmeet-Feedback” in the subject line. Any Feedback you submit
          to us will be considered non-confidential and non-proprietary to you.
          By submitting Feedback to us, you grant us a non-exclusive, worldwide,
          royalty-free, irrevocable, sub-licensable, perpetual license to use
          and publish those ideas and materials for any purpose, without
          compensation to you.
        </p>
        <h3>11.3 Third-Party Links and Services</h3>
        <p>
          The Services may contain links to other websites, businesses,
          resources and advertisers, and other sites may link to the Services.
          Clicking on a link will redirect you away from the Services to a
          third-party site or service. Frndsmeet is not responsible for
          examining or evaluating, and does not warrant the goods, services or
          offerings of any third party or the content of their websites or
          advertisements. Consequently, Frndsmeet does not assume any liability
          or responsibility for the accuracy, actions, products, services,
          practices, availability or content of such third parties. You should
          direct any concerns regarding other sites and services to their
          operators.
        </p>
        <h3>11.4 Assignment</h3>
        <p>
          You may not assign, transfer or delegate this Agreement and your
          rights and obligations hereunder without Frndsmeet’s prior written
          consent. Frndsmeet may, without restriction, assign, transfer or
          delegate this Agreement and any rights and obligations hereunder, at
          its sole discretion.
        </p>
        <h3>11.5 Changes to the Services or the Terms</h3>
        <p>
          Frndsmeet reserves the right, at any time and in our sole discretion,
          to amend, modify, suspend, or terminate, temporarily or permanently,
          the Services, and any part thereof, without notice to you. Frndsmeet
          shall have no liability to you or any other person or entity for any
          modification, suspension, or termination of the Services or any part
          thereof.
        </p>
        <p>
          Frndsmeet reserves the right to modify these Terms (effective on a
          prospective basis) at any time in accordance with this provision.
          Therefore, you should review these Terms regularly. If we make changes
          to these Terms, we will post the revised Terms on the Services and
          update the “Last Updated” date at the top of these Terms. If you do
          not terminate this Agreement before the date the revised Terms become
          effective, your continued access to or use of the Services will
          constitute acceptance of the revised Terms.
        </p>
        <p>
          Special terms or rules may apply to some Services. Any such terms are
          in addition to these Terms. In the event of any conflict or
          inconsistency between these Terms, our Privacy Notice, and any rules,
          restrictions, limitations, terms and/or conditions that may be
          communicated to users of the Services, Frndsmeet shall determine which
          rules, restrictions, limitations, terms and/or conditions shall
          control and prevail, in our sole discretion, and you specifically
          waive any right to challenge or dispute such determination.
        </p>
        <h3>11.6 No Third-Party Beneficiaries</h3>
        <p>
          This Agreement does not, and is not intended to, confer any rights or
          remedies upon any person other than the parties hereto.
        </p>
        <h3>11.7 No Waiver and Severability</h3>
        <p>
          Frndsmeet’s failure to enforce a provision of this Agreement is not a
          waiver of its right to do so later or to enforce any other provision.
          Except as expressly set forth in this Agreement, the exercise by
          either party of any of its remedies under this Agreement will be
          without prejudice to its other remedies under this Agreement or
          otherwise permitted under law.
        </p>
        <p>
          Except as explicitly provided herein, if any provision of this
          Agreement is held to be unenforceable for any reason, such provision
          will be reformed only to the extent necessary to make it enforceable,
          and such decision will not affect the enforceability of such provision
          under other circumstances, or of the remaining provisions hereof under
          all circumstances.
        </p>
        <h3>11.8 Governing Law and Venue</h3>
        <p>
          These Terms will be interpreted in accordance with the laws of the
          India, without regard to conflict-of-law provisions. Judicial proceedings
          (other than small claims proceedings) that are excluded from the Arbitration Agreement
          in Section 9 must be brought in the state or federal courts located in
          Portland, Oregon unless we both agree to some other location. You and
          we both consent to venue and personal jurisdiction in Portland,
          Oregon.
        </p>
        <h3>11.9 Entire Agreement</h3>
        <p>
          Except as it may be supplemented by additional terms and conditions,
          policies, guidelines or standards as provided herein, this Agreement
          constitutes the entire agreement between Frndsmeet and you pertaining
          to the subject matter hereof, and supersedes any and all prior oral or
          written understandings or agreements between Frndsmeet and you in
          relation to the access to and use of the Services.
        </p>

      </div>
    </>
  );
}

export default Terms;
